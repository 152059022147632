import UnityComponent from "../components/UnityComponent";
import TonConnectComponent from "../components/TonConnectComponent";
import UxuyComponent from "../components/UxuyComponent";

export default function Home() {
  return (
    <>
      <UnityComponent />
      <TonConnectComponent />
      <UxuyComponent />
    </>
  );
}
