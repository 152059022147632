import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import WebApp from "@twa-dev/sdk";
import { Buffer } from "buffer";
import CacheBuster from "react-cache-buster";

// 將 Buffer 全域化
window.Buffer = Buffer;

WebApp.ready();
WebApp.expand();
WebApp.disableVerticalSwipes();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CacheBuster>
    <App />
  </CacheBuster>
);
