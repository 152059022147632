import React, { useState, useEffect, useLayoutEffect } from "react";
import { WalletTgSdk } from "@uxuycom/web3-tg-sdk";
import { ethers } from "ethers";
import { CHAINS, SIGNIN_CONTRACT_ABI } from "@/constants/common";

const DEFAULT_CHAIN_ID = "0x38"; // BSC
const { ethereum } = new WalletTgSdk({
  metaData: {
    name: "Tonshi",
    icon: "https://miniapp.tonshi.xyz/logo.png",
  },
});

const defaultChainConfig = CHAINS.find(
  (chain) => String(chain.chainId) === DEFAULT_CHAIN_ID
);

const UxuyComponent = () => {
  const [state, setState] = useState({
    // address: null,
    // chainId: '',
    signature: null,
    // transaction: defaultChainConfig.transaction,
    transaction_token: defaultChainConfig.transaction_token,
    transaction_contract: defaultChainConfig.transaction_contract,
    chainRPCs: defaultChainConfig.chainRPCs,
  });

  const [chainId, setChainId] = useState("0x38");
  const [address, setAddress] = useState("");

  const [signMessageContext, setSignMessageContext] = useState(
    "Wellcome to Tonshi!! Sign this message to prove your identity."
  );
  const [verifyMessage, setVerifyMessage] = useState(null);

  useLayoutEffect(() => {
    init();
  }, []);

  const initEventListener = () => {
    // events
    function handleAccountsChanged(accounts) {
      const {
        ok: verifyMessageOk,
        result: verifyMessageResult,
        message: verifyMessageMessage,
      } = verifySignMessage(verifyMessage);
      if (verifyMessageOk && verifyMessageResult === accounts[0]) {
        setAddress(accounts[0]);
      }
    }
    function handleChainChanged(_chainId) {
      setChainId("0x" + Number(_chainId).toString(16));
    }

    ethereum.on("accountsChanged", handleAccountsChanged);
    ethereum.on("chainChanged", handleChainChanged);
  };
  // get chainId, address
  const init = async () => {
    window?.Telegram?.WebApp?.expand?.();

    const accounts = await ethereum.request({
      method: "eth_accounts",
      params: [],
    });
    const chainId = await ethereum.request({
      method: "eth_chainId",
      params: [],
    });
    const isConnected = accounts[0];
    setChainId(chainId);
    setAddress(accounts[0]);
    initEventListener();
    isConnected && switchChain(DEFAULT_CHAIN_ID);
  };

  useEffect(() => {
    if (!chainId) {
      return;
    }

    const chainConfig = CHAINS.find(
      (chain) => parseInt(chain.chainId) == parseInt(chainId)
    );
    if (!chainConfig) {
      return;
    }

    const RPC_URL = chainConfig?.chainRPCs?.[0] || "";

    setState({
      ...state,
      //   transaction: chainConfig?.transaction,
      //   transaction_token: { ...chainConfig.transaction_token, rpc: RPC_URL },
      transaction_contract: chainConfig?.transaction_contract,
      chainRPCs: chainConfig?.chainRPCs,
    });
  }, [chainId]);

  useEffect(() => {
    // 註冊事件監聽器
    document.addEventListener("unityUxuy_ConnectToWallet", connectWallet);
    document.addEventListener("unityUxuy_DisconnectWallet", disconnectWallet);
    document.addEventListener("unityUxuy_GetWalletAddress", getWalletAddress);
    document.addEventListener("unityUxuy_GetBnbBalance", getBnbBalance);
    document.addEventListener("unityUxuy_DailySignIn", signInEvent);

    // 清理事件監聽器
    return () => {
      document.removeEventListener("unityUxuy_ConnectToWallet", connectWallet);
      document.removeEventListener(
        "unityUxuy_DisconnectWallet",
        disconnectWallet
      );
      document.removeEventListener(
        "unityUxuy_GetWalletAddress",
        getWalletAddress
      );
      document.removeEventListener("unityUxuy_GetBnbBalance", getBnbBalance);
      document.removeEventListener("unityUxuy_DailySignIn", signInEvent);
    };
  }, [state, address, ethereum]);

  useEffect(() => {
    if (address) {
      try {
        // 把錢包資料傳遞給 Unity
        window.unityInstance.SendMessage(
          "UxuyWalletAccount",
          "UserAccountData",
          address
        );
      } catch (error) {
        console.error("Error sending wallet data to Unity:", error);
        // 把錯誤狀態傳遞給 Unity
      }
    }
  }, [address]);

  // Connect Wallet Event
  const connectWallet = async (event) => {
    try {
      if (!address) {
        await ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });

        const accounts = await ethereum.request({
          method: "eth_accounts",
          params: [],
        });
        const chainId = await ethereum.request({
          method: "eth_chainId",
          params: [],
        });

        const {
          ok: signMessageOk,
          result: signMessageResult,
          message: signMessageMessage,
        } = await signMessage();
        if (signMessageOk) {
          const {
            ok: verifyMessageOk,
            result: verifyMessageResult,
            message: verifyMessageMessage,
          } = verifySignMessage(signMessageResult);
          if (verifyMessageOk && verifyMessageResult === accounts[0]) {
            setAddress(accounts[0]);
            setChainId(chainId);
            switchChain(DEFAULT_CHAIN_ID);
            console.log("Connected to wallet:", accounts[0]);
            return { ok: true, userAddress: accounts[0] };
          }
        }
        // 沒有完成簽名或簽名錯誤，解除連接錢包
        disconnectWallet();
        return { ok: false, message: signMessageMessage || "error" };
      } else {
        console.log("Already connected to wallet:", address);
        return address;
      }
    } catch (error) {
      console.error("Connection failed:", error);
    }
  };
  // Disconnect Wallet Event
  const disconnectWallet = async (event) => {
    try {
      ethereum?.disconnect && ethereum?.disconnect?.();
      console.log("Disconnected from wallet:", address);
      setAddress("");
    } catch (error) {
      console.error("Disconnection failed:", error);
    }
  };

  const getWalletAddress = async (event) => {
    try {
      window.unityInstance.SendMessage(
        "UxuyWalletAccount",
        "GetUserWalletAddress",
        address
      );
    } catch (error) {
      console.error("Error getting wallet status:", error);
    }
  };

  const getBnbBalance = async (event) => {
    try {
      if (!address) {
        connectWallet();
        return;
      } else {
        const balance = await ethereum.request({
          method: "eth_getBalance",
          params: [address, "latest"],
        });
        window.unityInstance.SendMessage(
          "UxuyWalletAccount",
          "OnGetBnbBalance",
          Number(balance).toString()
        );
      }
    } catch (error) {
      console.error("Error getting Bnb balance:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };

  // Switch chian Event
  const switchChain = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (error) {
      console.error("Chain switch failed:", error);
    }
  };

  // Sign Message Event
  const signMessage = async () => {
    const signMessage = ethers.isHexString(signMessageContext)
      ? signMessageContext
      : ethers.hexlify(ethers.toUtf8Bytes(signMessageContext));
    try {
      const result = await ethereum.request({
        method: "personal_sign",
        params: [signMessage, address],
      });
      setState({ ...state, signature: result, message: signMessage });
      return { ok: true, result };
    } catch (error) {
      console.error("Message signing failed:", error);
      return { ok: false, message: error.message || "error" };
    }
  };
  function verifySignMessage(signature) {
    try {
      if (!signature) {
        return { ok: false, message: "No signature provided." };
      }

      const recoveredAddress = ethers.verifyMessage(
        signMessageContext,
        signature
      );

      setVerifyMessage(recoveredAddress);
      console.log("Signature verified:", recoveredAddress);
      return { ok: true, result: recoveredAddress };
    } catch (error) {
      console.log("Signature verification failed:", error);
      return { ok: false, message: error.message || "error" };
    }
  }

  // Sign In Contract Event
  const signInEvent = async (event) => {
    if (!address) {
      await connectWallet();
      return;
    } else {
      // 獲取傳入參數
      const taskId = event.detail.taskId;
      const userId = event.detail.userId;

      const contractAddress = state.transaction_contract.signinContractAddress;
      // Create an instance of ethers.Contract
      const iface = new ethers.Interface(SIGNIN_CONTRACT_ABI);
      const data = iface.encodeFunctionData("signIn", [
        userId.toString(),
        taskId.toString(),
      ]);

      // const amount = ethers.utils.parseUnits('1', 'ether'); // Adjust amount as needed
      const transaction = {
        from: address,
        to: contractAddress,
        // value: "0x",
        data: data,
      };
      try {
        const result = await ethereum.request({
          method: "eth_sendTransaction",
          params: [transaction],
        });
        if (result) {
          // 回傳交易資料給 Unity
          console.log("Transaction sent successfully");
          const returnData = {
            transactionHash: result,
            taskId,
          };
          const jsonString = JSON.stringify(returnData);
          window.unityInstance.SendMessage(
            "SignInResult",
            "OnDailySignInResult",
            jsonString
          );
        } else {
          throw new Error("Transaction failed");
        }
      } catch (error) {
        console.error("Error while sign in with Uxuy:", error);
        if (window.unityInstance) {
          const errorMessage = error.message || error.toString();
          window.unityInstance.SendMessage("Error", "Error", errorMessage);
        }
      }
    }
  };
};

export default UxuyComponent;
