/* global BigInt */
import { useState, useEffect } from "react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useTonConnect } from "@/hooks/useTonconnect";
import { JettonWallet } from "@/wrappers/JettonWallet";
import { v4 as uuidv4 } from "uuid";
import TonWeb from "tonweb";
import {
  OKXTonConnect,
  OkxConnectError,
  OKX_CONNECT_ERROR_CODES,
} from "okxconnect";

import {
  INVOICE_WALLET_ADDRESS,
  USDT_MASTER_ADDRESS,
  CATI_MASTER_ADDRESS,
  SIGN_IN_CONTRACT_ADDRESS,
} from "@/constants/common.js";
import { JETTON_TRANSFER_GAS_FEES } from "@/constants/fees.constants.js";

import { beginCell, toNano } from "@ton/ton";
import { Address, SendMode } from "@ton/core";

const TonConnectComponent = () => {
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { sender, tonClient } = useTonConnect();
  const address = useTonAddress();
  const tonweb = new TonWeb();

  const okxTonConnect = new OKXTonConnect({
    metaData: {
      name: "Tonshi",
      icon: "https://miniapp.tonshi.xyz/logo.png",
    },
  });

  useEffect(() => {
    if (address) {
      try {
        // 把資料包成 JSON 格式，傳給 Unity
        const walletData = {
          address: address.toString() || "",
          walletName: tonConnectUI.walletInfo?.name || "",
        };
        console.log("Wallet data:", walletData);
        // window.unityInstance.SendMessage(
        //   "TonWalletAccount",
        //   "UserAccountData",
        //   JSON.stringify(walletData)
        // );
      } catch (error) {
        console.error("Error sending wallet data to Unity:", error);
        // 把錯誤狀態傳遞給 Unity
      }
    }
  }, [address]);

  // 在 walletModal 被關閉時，更新連接狀態
  // useEffect(() => {
  //   tonConnectUI.onSingleWalletModalStateChange((state) => {
  //     const status = state.status;
  //     if (status === "opened") {
  //       console.log("Single Wallet Modal opened:", state.walletInfo.name);
  //     } else if (status === "closed") {
  //       console.log("Single Wallet Modal closed");
  //       let address = tonConnectUI.connector?._wallet?.account?.address || null;
  //       console.log("Address:", address);
  //       // 傳送資料給 Unity
  //     } else {
  //       console.log("Single Wallet Modal state changed:", state);
  //     }
  //   });

  //   tonConnectUI.onModalStateChange((state) => {
  //     const status = state.status;
  //     if (status === "opened") {
  //       console.log("Modal opened");
  //     } else if (status === "closed") {
  //       console.log("Modal closed");
  //       let address = tonConnectUI.connector?._wallet?.account?.address || null;
  //       console.log("Address:", address);
  //       // 傳送資料給 Unity
  //     } else {
  //       console.log("Modal state changed:", state);
  //     }
  //   });
  // }, [tonConnectUI]);

  const [tonBalance, setTonBalance] = useState(0);
  useEffect(() => {
    const fetchBalance = async () => {
      if (!address) return;
      // 将钱包地址转换为 Address 对象
      const balance = await tonweb.getBalance(address);
      console.log("Ton balance:", balance);
      setTonBalance(balance);
    };

    fetchBalance();
  }, [address]);

  // 連接 Ton Wallet 功能
  const handleConnectToWallet = async (event) => {
    console.log("ConnectToWallet");
    try {
      tonConnectUI.openModal();
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  // 解除連接 Ton Wallet 功能
  const handleDisconnectWallet = async (event) => {
    console.log("DisconnectWallet");
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  // 顯示 Ton Wallet 連接狀態
  const handleGetUserWalletAddress = async (event) => {
    console.log("GetUserWalletAddress");
    try {
    } catch (error) {
      console.error("Error getting wallet status:", error);
    }
  };

  // 商城 Ton 付費功能
  const handleBuyItemWithTon = async (event) => {
    console.log("BuyItemWithTon");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceNanoTon = event.detail.priceNanoTon || 10000000;
        const userId = event.detail.userId || "123";
        const itemId = event.detail.itemId || "456";
        const walletAddress =
          tonConnectUI?.connector?._wallet?.account?.address;
        if (!walletAddress) {
          throw new Error("Please connect wallet first");
        }
        // 建立交易訊息
        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(`User ${userId} buying item ${itemId} with TON`)
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: INVOICE_WALLET_ADDRESS.toString(),
              amount: `${priceNanoTon}`,
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log("Transaction result:", result);
        // 將交易結果回傳給 Unity
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        window.unityInstance.SendMessage("Error", "Error", error);
      }
    }
  };

  // 商城 Usdt 付費功能
  const handleBuyItemWithUsdt = async (event) => {
    console.log("BuyItemWithUsdt");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceUsdtCent = event.detail.priceUsdtCent || 1;
        const userId = event.detail.userId || "123";
        const itemId = event.detail.itemId || "456";
        // 找出用戶的 Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
        );
        const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        console.log(jettonWalletAddress);
        // 檢查用戶餘額是否足夠
        // const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
        //   tonweb.provider,
        //   {
        //     address: jettonWalletAddress,
        //   }
        // );
        // const walletData = await jettonWalletToGetData.getData();
        // const walletBalance = walletData.balance.toString();
        // if (BigInt(walletBalance) < BigInt(priceUsdtCent * 10000)) {
        //   console.log("Insufficient balance");
        //   return {
        //     ok: false,
        //     message: "Insufficient balance",
        //     itemId,
        //   };
        // }
        // 準備交易訊息
        const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail(`User ${userId} buying item ${itemId} with USDT`)
          .endCell();
        const body = beginCell()
          .storeUint(0xf8a7ea5, 32) // jetton transfer op code
          .storeUint(0, 64) // query_id:uint64
          .storeCoins(priceUsdtCent * 10000) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
          .storeAddress(INVOICE_WALLET_ADDRESS) // destination:MsgAddress
          .storeAddress(Address.parse(address)) // response_destination:MsgAddress
          .storeBit(0) // no custom payload
          .storeCoins(toNano("0.000001")) // forward amount - if >0, will send notification message
          .storeBit(1) // we store forwardPayload as a reference
          .storeRef(forwardPayload)
          .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: jettonWalletAddress.toString(true, true, true), // sender jetton wallet
              amount: toNano(0.05).toString(), // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64"), // payload with jetton transfer body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        // 回傳交易資料給 Unity
        console.log("Transaction sent successfully");
        const returnData = {
          ok: true,
          message: "Transaction sent successfully",
          boc: result.boc,
          itemId,
        };
        const stringJson = JSON.stringify(returnData);
        console.log("Return data:", stringJson);
      }
    } catch (error) {
      console.log(
        "Error during transaction check:",
        error.message || error.toString()
      );
    }
  };
  const handleBuyItemWithCati = async (event) => {
    console.log("BuyItemWithCati");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const priceNanoCati = event.detail.priceNanoCati || 1000000000n;
        const userId = event.detail.userId || 123;
        const itemId = event.detail.itemId || 123;
        // 找出用戶的 Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: CATI_MASTER_ADDRESS.toString() } // USDT Master
        );
        const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        console.log(jettonWalletAddress.toString(true, true, true));
        // 準備交易訊息
        const forwardPayload = beginCell()
          .storeUint(0, 32) // 0 opcode means we have a comment
          .storeStringTail(`User ${userId} buying item ${itemId} with CATI`)
          .endCell();
        const body = beginCell()
          .storeUint(0xf8a7ea5, 32) // jetton transfer op code
          .storeUint(0, 64) // query_id:uint64
          .storeCoins(priceNanoCati) // amount:(VarUInteger 16) -  Jetton amount for transfer
          .storeAddress(INVOICE_WALLET_ADDRESS) // destination:MsgAddress
          .storeAddress(Address.parse(address)) // response_destination:MsgAddress
          .storeBit(0) // no custom payload
          .storeCoins(toNano("0.000001")) // forward amount - if >0, will send notification message
          .storeBit(1) // we store forwardPayload as a reference
          .storeRef(forwardPayload)
          .endCell();
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: jettonWalletAddress.toString(true, true, true), // sender jetton wallet
              amount: toNano(0.05).toString(), // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64"), // payload with jetton transfer body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        if (result) {
          // 回傳交易資料給 Unity
          console.log("Transaction sent successfully");
          const returnData = {
            boc: result.boc,
            itemId,
          };
          const jsonString = JSON.stringify(returnData);
          window.unityInstance.SendMessage(
            "BuyItemResult",
            "OnBuyItemWithCatiResult",
            jsonString
          );
        } else {
          throw new Error("Transaction failed");
        }
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        const errorMessage = error.message || error.toString();
        window.unityInstance.SendMessage("Error", "Error", errorMessage);
      }
    }
  };

  const handleGetUsdtBalance = async (event) => {
    console.log("GetUsdtBalance");
    let jettonWalletAddress = "";
    let usdtBalance = 0;
    const address = "UQAUAm1BKp_FYbmLQ4BYQDohTr3elOMcjJ8W7onmas5VWjOO";
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        console.log(tonweb.provider);
        // 找出用戶的 Usdt Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: USDT_MASTER_ADDRESS.toString() } // USDT Master
        );
        jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        // 獲取用戶 Usdt Jetton 餘額
        const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
          tonweb.provider,
          {
            address: jettonWalletAddress,
          }
        );
        const walletData = await jettonWalletToGetData.getData();
        usdtBalance = walletData.balance.toString();
        jettonWalletAddress = jettonWalletAddress.toString(true, true, true);
      }
    } catch (error) {
      console.error("Error getting Ton balance:", error);
    } finally {
      // 回傳用戶 Usdt Jetton 餘額跟 Jetton Wallet 地址給 Unity
      const jsonString = JSON.stringify({
        jettonWalletAddress,
        usdtBalance,
      });
      // window.unityInstance.SendMessage(
      //   "TonWalletAccount",
      //   "OnGetUsdtBalance",
      //   jsonString
      // );
      console.log("Usdt Balance:", usdtBalance);
      console.log("Jetton Wallet Address:", jettonWalletAddress.toString());
    }
  };

  const handleGetCatiBalance = async (event) => {
    console.log("GetCatiBalance");
    let jettonWalletAddress = "";
    let catiBalance = 0;
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        // 找出用戶的 Cati Jetton Wallet
        const jettonMinter = new TonWeb.token.jetton.JettonMinter(
          tonweb.provider,
          { address: CATI_MASTER_ADDRESS.toString() } // CATI Master
        );
        jettonWalletAddress = await jettonMinter.getJettonWalletAddress(
          new TonWeb.utils.Address(address)
        );
        // 獲取用戶 Cati Jetton 餘額
        const jettonWalletToGetData = new TonWeb.token.jetton.JettonWallet(
          tonweb.provider,
          {
            address: jettonWalletAddress,
          }
        );
        const walletData = await jettonWalletToGetData.getData();
        catiBalance = walletData.balance.toString();
        jettonWalletAddress = jettonWalletAddress.toString(true, true, true);
      }
    } catch (error) {
      console.error("Error getting Ton balance:", error);
    } finally {
      // 回傳用戶 Cati Jetton 餘額跟 Jetton Wallet 地址給 Unity
      const jsonString = JSON.stringify({
        jettonWalletAddress,
        catiBalance,
      });
      // window.unityInstance.SendMessage(
      //   "TonWalletAccount",
      //   "OnGetCatiBalance",
      //   jsonString
      // );
      console.log("Cati Balance:", catiBalance);
      console.log("Jetton Wallet Address:", jettonWalletAddress.toString());
    }
  };

  // 每日簽到功能
  const handleDailyCheckIn = async (event) => {
    console.log("DailyCheckIn");
    try {
      if (!address) {
        handleConnectToWallet();
      } else {
        const walletAddress =
          tonConnectUI?.connector?._wallet?.account?.address;
        if (!walletAddress) {
          throw new Error("Please connect wallet first");
        }
        // 建立交易訊息
        const body = beginCell()
          .storeUint(1, 32) // op (daily_sign_in)
          .storeUint(0, 64) // query id
          .endCell();
        const transaction = {
          validUntil: Math.floor(new Date() / 1000) + 360,
          messages: [
            {
              address: SIGN_IN_CONTRACT_ADDRESS.toString(),
              amount: toNano(0.05).toString(),
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        // 發送交易訊息
        const result = await tonConnectUI.sendTransaction(transaction);
        console.log("Transaction result:", result);
        // 將交易結果回傳給 Unity
      }
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        window.unityInstance.SendMessage("Error", "Error", error);
      }
    }
  };

  // 連接 OKX Wallet 功能
  const handleConnectOKXWallet1 = async (event) => {
    console.log("ConnectOKXWallet");
    try {
      if (address) handleDisconnectWallet();
      tonConnectUI.openSingleWalletModal("OKX Wallet");
    } catch (error) {
      console.error("Error connecting to wallet:", error);
    }
  };

  const handleConnectOKXWallet2 = async (event) => {
    try {
      const restoreResult =
        await window.okxTonWallet.tonconnect.restoreConnection();
      if (restoreResult.event !== "connect") {
        // Restore 失敗，重新發送連接請求
        const connectResult = await window.okxTonWallet.tonconnect.connect(2, {
          manifestUrl: "https://tonshi-21cac.web.app/manifest.json",
          items: [
            { name: "ton_addr" },
            // { name: "ton_proof", payload: "userSignInMessage" },
          ],
        });
        if (connectResult.event === "connect") {
          return {
            isConnected: true,
            okxAddress: connectResult.payload.items[0].address,
          };
        } else {
          return {
            isConnected: false,
            message: connectResult.payload.message,
          };
        }
      } else {
        return {
          isConnected: true,
          okxAddress: restoreResult.payload.items[0].address,
        };
      }
    } catch (error) {
      console.error("Error connecting to OKX wallet:", error);
      return {
        isConnected: false,
        message: error.message || error.toString(),
      };
    }
  };
  const handleDisconnectOKXWallet2 = async (event) => {
    const result = await window.okxTonWallet.tonconnect.send({
      method: "disconnect",
      params: [],
    });
  };
  // 顯示 OKX Wallet 連接狀態
  const handleGetUserOKXWalletAddress = async (event) => {
    console.log("GetUserOKXWalletAddress");
    try {
    } catch (error) {
      console.error("Error getting wallet status:", error);
    }
  };
  // OKX 專屬每日簽到功能
  const handleOKXDailyCheckIn = async (event) => {
    console.log("OKXDailyCheckIn");
    try {
      // 連接 OKX 錢包
      const { isConnected, okxAddress, message } =
        await handleConnectOKXWallet2();
      // 確認連接成功
      if (!isConnected || !okxAddress)
        throw new Error("Please connect wallet first");
      // 建立交易訊息
      const body = beginCell()
        .storeUint(1, 32) // op (daily_sign_in)
        .storeUint(0, 64) // query id
        .endCell();
      const transactionJson = {
        validUntil: Math.floor(new Date() / 1000) + 360,
        network: "-239",
        from: okxAddress,
        messages: [
          {
            address: SIGN_IN_CONTRACT_ADDRESS.toString(),
            amount: toNano(0.05).toString(),
            payload: body.toBoc().toString("base64"), // payload with comment in body
          },
        ],
      };
      // 發送交易訊息
      const result = await window.okxTonWallet.tonconnect.send({
        method: "sendTransaction",
        params: [transactionJson],
      });
      console.log("Transaction result:", result);
      // 將交易結果回傳給 Unity
    } catch (error) {
      console.error("Error buying item with Ton:", error);
      if (window.unityInstance) {
        window.unityInstance.SendMessage("Error", "Error", error);
      }
    }
  };

  const restoreOKXConnection = async () => {
    try {
      await okxTonConnect.restoreConnection();
    } catch (error) {
      console.error("Error connecting to OKX wallet:", error);
    }
  };
  const handleConnectOKXWallet3 = async (event) => {
    try {
      if (!okxTonConnect.connected) {
        // Restore 失敗，重新發送連接請求
        await okxTonConnect.connect({
          tonProof: "Tonshi - OKX Wallet Sign-in",
          redirect: "tg://resolve",
          openUniversalLink: true,
        });
      }
    } catch (error) {
      let message = error.message || error.toString();
      if (error instanceof OkxConnectError) {
        if (error.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR) {
          message = "User reject";
        } else if (
          error.code === OKX_CONNECT_ERROR_CODES.ALREADY_CONNECTED_ERROR
        ) {
          message = "Already connected";
        }
      }
      console.error("Error connecting to OKX wallet:", error);
      // 傳遞錯誤訊息給 Unity
      return {
        ok: false,
        message: error.message || error.toString(),
      };
    }
  };
  const handleDisconnectOKXWallet3 = async (event) => {
    try {
      await okxTonConnect.disconnect();
    } catch (error) {
      let message = error.message || error.toString();
      if (error instanceof OkxConnectError) {
        switch (error.code) {
          case OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR:
            message = "Not connected";
            break;
          default:
            message = "Unknown error happened";
            break;
        }
      }
      console.error("Error disconnecting from OKX wallet:", error);
      alert(message);
      // 傳遞錯誤訊息給 Unity
      return {
        ok: false,
        message: message,
      };
    }
  };
  const handleGetUserOKXWalletAddress3 = async (event) => {
    try {
      if (!okxTonConnect.connected) {
        return { ok: false, message: "Not connected" };
      } else {
        const address = Address.parse(okxTonConnect.account.address).toString(
          true,
          true,
          true
        );
        return { ok: true, address: address };
      }
    } catch (error) {
      console.error("Error getting wallet status:", error);
      // 傳遞錯誤訊息給 Unity
      return {
        ok: false,
        message: error.message || error.toString(),
      };
    }
  };
  const handleOKXDailyCheckIn3 = async (event) => {
    try {
      if (!okxTonConnect.connected) {
        await handleConnectOKXWallet3();
      } else {
        const body = beginCell()
          .storeUint(1, 32) // op (daily_sign_in)
          .storeUint(0, 64) // query id
          .endCell();
        let transactionRequest = {
          validUntil: Date.now() / 1000 + 360,
          messages: [
            {
              address: SIGN_IN_CONTRACT_ADDRESS.toString(),
              amount: toNano(0.05).toString(),
              payload: body.toBoc().toString("base64"), // payload with comment in body
            },
          ],
        };
        let requestOptions = {
          onRequestSent: () => {
            //requestMsgSend
          },
        };
        alert("Sending transaction to OKX wallet...");

        const result = await okxTonConnect.sendTransaction(
          transactionRequest,
          requestOptions
        );

        alert(result);
      }
    } catch (error) {
      if (error instanceof OkxConnectError) {
        switch (error.code) {
          case OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR:
            alert("You rejected the transaction.");
            break;
          case OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR:
            alert("Not connected");
            break;
          default:
            alert("Unknown error happened");
            break;
        }
      } else {
        alert("Unknown error happened");
      }
      alert(error.message || error.toString());
    }
  };

  const [okxWalletAddress, setOkxWalletAddress] = useState("");
  const unsubscribe = okxTonConnect.onStatusChange(
    (walletInfo) => {
      console.log("OKX Wallet status change:", walletInfo);
      if (walletInfo.account?.address) {
        try {
          alert(`OKX Wallet connected: ${walletInfo.account.address}`);
          console.log(`OKX Wallet connected: ${walletInfo.account.address}`);
          setOkxWalletAddress(walletInfo.account.address);
          // 把資料包成 JSON 格式，傳給 Unity
          const walletData = {
            type: "okxConnect",
            isConnected: true,
          };
          window.unityInstance.SendMessage(
            "TonWalletAccount",
            "UserAccountData",
            JSON.stringify(walletData)
          );
        } catch (error) {
          console.error("Error sending wallet data to Unity:", error);
        }
      } else {
        console.log("OKX Wallet disconnected");
      }
    },
    (err) => {
      console.log("Connection status:", err);
      alert(err.message || err.toString());
    }
  );

  useEffect(() => {
    if (okxWalletAddress) {
      unsubscribe();
    }
  }, [okxWalletAddress]);

  return (
    <div>
      <button onClick={handleConnectToWallet}>Connect to Wallet</button>
      <button onClick={handleDisconnectWallet}>Disconnect Wallet</button>
      <button onClick={handleGetUserWalletAddress}>
        Get User Wallet Address
      </button>
      <button onClick={handleBuyItemWithTon}>Buy With TON</button>
      <button onClick={handleBuyItemWithUsdt}>Buy With USDT</button>
      <button onClick={handleBuyItemWithCati}>Buy With CATI</button>
      <button onClick={handleGetUsdtBalance}>Check USDT Balance</button>
      <button onClick={handleGetCatiBalance}>Check CATI Balance</button>
      <button onClick={handleDailyCheckIn}>Daily Check In</button>
      <button onClick={handleConnectOKXWallet1}>Connect To OKX 1</button>
      <button onClick={handleConnectOKXWallet2}>Connect To OKX 2</button>
      <button onClick={handleDisconnectOKXWallet2}>Disconnect OKX2</button>
      <button onClick={handleConnectOKXWallet3}>Connect To OKX 3</button>
      <button onClick={handleDisconnectOKXWallet3}>Disconnect OKX3</button>
      <button onClick={handleOKXDailyCheckIn3}>OKX Daily Check In 3</button>
    </div>
  );
};

export default TonConnectComponent;
