import { Address } from "@ton/core";

export const DEFAULT_URL = "https://tonshi-21cac.web.app";
export const BOT_URL = "https://t.me/OfficialTonshi_bot";
export const USDT_MASTER_ADDRESS = Address.parse(
  "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs"
);
export const CATI_MASTER_ADDRESS = Address.parse(
  "EQD-cvR0Nz6XAyRBvbhz-abTrRC6sI5tvHvvpeQraV9UAAD7"
);
export const INVOICE_WALLET_ADDRESS = Address.parse(
  "UQC4vgdQAGYQ4c9KHyK5PYqKEoVhpE0V6z5nwJ3uEju-qHmF"
);
export const SIGN_IN_CONTRACT_ADDRESS = Address.parse(
  "EQBL2gC-1xpii5MJ7eTN2TsPHQvCDSPw_hbaie4nNFX0hIcb"
);

export const CHAINS = [
  {
    chainId: "0x38",
    chainKey: "bnbchain",
    chainName: "BNB Chain",
    chainSymbol: "BNB",
    chainDecimals: 18,
    chainRPCs: ["https://bscrpc.com", "https://bsc-dataseed2.ninicoin.io"],
    explorer: "https://bscscan.com/",
    // transaction: {
    //   to: "0xF6d0C52feE610c8270a7793B574e603EA54352AE",
    //   value: "0.0001",
    // },
    // transaction_token: {
    //   contractAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    //   toAddress: "0xF6d0C52feE610c8270a7793B574e603EA54352AE",
    //   amount: "0.0001",
    // },
    transaction_contract: {
      signinContractAddress: "0xA1604852afca7A477aC0515DE1c4362A3b9e0c21",
    },
  },
];

export const SIGNIN_CONTRACT_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "userAddress",
        type: "address",
      },
      {
        indexed: true,
        internalType: "string",
        name: "userId",
        type: "string",
      },
      {
        indexed: true,
        internalType: "string",
        name: "taskId",
        type: "string",
      },
    ],
    name: "SignIn",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "userId",
        type: "string",
      },
      {
        internalType: "string",
        name: "taskId",
        type: "string",
      },
    ],
    name: "signIn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
