import React, { useState, useEffect, useLayoutEffect } from "react";
import { WalletTgSdk } from "@uxuycom/web3-tg-sdk";
import { ethers } from "ethers";
import { CHAINS, SIGNIN_CONTRACT_ABI } from "@/constants/common";

const DEFAULT_CHAIN_ID = "0x38"; // BSC
const { ethereum } = new WalletTgSdk({
  metaData: {
    name: "Tonshi",
    icon: "https://miniapp.tonshi.xyz/logo.png",
  },
});

const defaultChainConfig = CHAINS.find(
  (chain) => String(chain.chainId) === DEFAULT_CHAIN_ID
);

const UxuyComponent = () => {
  const [state, setState] = useState({
    // address: null,
    // chainId: '',
    signature: null,
    // transaction: defaultChainConfig.transaction,
    transaction_token: defaultChainConfig.transaction_token,
    transaction_contract: defaultChainConfig.transaction_contract,
    chainRPCs: defaultChainConfig.chainRPCs,
  });

  const [chainId, setChainId] = useState("0x38");
  const [address, setAddress] = useState("");

  const [signMessageContext, setSignMessageContext] = useState(
    "Wellcome to Tonshi!! Sign this message to prove your identity."
  );
  const [verifyMessage, setVerifyMessage] = useState(null);

  useLayoutEffect(() => {
    init();
  }, []);

  const initEventListener = () => {
    // events
    function handleAccountsChanged(accounts) {
      const {
        ok: verifyMessageOk,
        result: verifyMessageResult,
        message: verifyMessageMessage,
      } = verifySignMessage(verifyMessage);
      if (verifyMessageOk && verifyMessageResult === accounts[0]) {
        setAddress(accounts[0]);
      }
    }
    function handleChainChanged(_chainId) {
      setChainId("0x" + Number(_chainId).toString(16));
    }

    ethereum.on("accountsChanged", handleAccountsChanged);
    ethereum.on("chainChanged", handleChainChanged);
  };
  // get chainId, address
  const init = async () => {
    window?.Telegram?.WebApp?.expand?.();

    const accounts = await ethereum.request({
      method: "eth_accounts",
      params: [],
    });
    const chainId = await ethereum.request({
      method: "eth_chainId",
      params: [],
    });
    const isConnected = accounts[0];
    setChainId(chainId);
    setAddress(accounts[0]);
    initEventListener();
    isConnected && switchChain(DEFAULT_CHAIN_ID);
  };

  useEffect(() => {
    if (!chainId) {
      return;
    }

    const chainConfig = CHAINS.find(
      (chain) => parseInt(chain.chainId) == parseInt(chainId)
    );
    if (!chainConfig) {
      return;
    }

    const RPC_URL = chainConfig?.chainRPCs?.[0] || "";

    setState({
      ...state,
      //   transaction: chainConfig?.transaction,
      //   transaction_token: { ...chainConfig.transaction_token, rpc: RPC_URL },
      transaction_contract: chainConfig?.transaction_contract,
      chainRPCs: chainConfig?.chainRPCs,
    });
  }, [chainId]);

  // Connect Wallet Event
  const connectWallet = async () => {
    try {
      if (!address) {
        await ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });

        const accounts = await ethereum.request({
          method: "eth_accounts",
          params: [],
        });
        const chainId = await ethereum.request({
          method: "eth_chainId",
          params: [],
        });

        const {
          ok: signMessageOk,
          result: signMessageResult,
          message: signMessageMessage,
        } = await signMessage();
        if (signMessageOk) {
          const {
            ok: verifyMessageOk,
            result: verifyMessageResult,
            message: verifyMessageMessage,
          } = verifySignMessage(signMessageResult);
          console.log("address", accounts[0]);
          console.log("Verify message result:", verifyMessageResult);
          if (verifyMessageOk && verifyMessageResult === accounts[0]) {
            setAddress(accounts[0]);
            setChainId(chainId);
            switchChain(DEFAULT_CHAIN_ID);
            console.log("Connected to wallet:", accounts[0]);
            return { ok: true, userAddress: accounts[0] };
          }
        }
        // 沒有完成簽名或簽名錯誤，解除連接錢包
        console.log("Sign message failed:", signMessageMessage || "error");
        disconnectWallet();
        return { ok: false, message: signMessageMessage || "error" };
      } else {
        console.log("Already connected to wallet:", address);
        return address;
      }
    } catch (error) {
      console.error("Connection failed:", error);
    }
  };
  // Disconnect Wallet Event
  const disconnectWallet = async () => {
    try {
      ethereum?.disconnect && ethereum?.disconnect?.();
      console.log("Disconnected from wallet:", address);
      setAddress("");
    } catch (error) {
      console.error("Disconnection failed:", error);
    }
  };

  // Switch chian Event
  const switchChain = async (chainId) => {
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (error) {
      console.error("Chain switch failed:", error);
    }
  };

  // Sign Message Event
  const signMessage = async () => {
    const signMessage = ethers.isHexString(signMessageContext)
      ? signMessageContext
      : ethers.hexlify(ethers.toUtf8Bytes(signMessageContext));
    try {
      const result = await ethereum.request({
        method: "personal_sign",
        params: [signMessage, address],
      });
      setState({ ...state, signature: result, message: signMessage });
      return { ok: true, result };
    } catch (error) {
      console.error("Message signing failed:", error);
      return { ok: false, message: error.message || "error" };
    }
  };
  function verifySignMessage(signature) {
    try {
      if (!signature) {
        return { ok: false, message: "No signature provided." };
      }

      const recoveredAddress = ethers.verifyMessage(
        signMessageContext,
        signature
      );

      setVerifyMessage(recoveredAddress);
      console.log("Signature verified:", recoveredAddress);
      return { ok: true, result: recoveredAddress };
    } catch (error) {
      console.log("Signature verification failed:", error);
      return { ok: false, message: error.message || "error" };
    }
  }

  // Approve Contract Event
  const signInEvent = async () => {
    const contractAddress = state.transaction_contract.signinContractAddress;

    // Create an instance of ethers.Contract
    const abiCoder = new ethers.AbiCoder();
    const iface = new ethers.Interface(SIGNIN_CONTRACT_ABI);
    const data = iface.encodeFunctionData("signIn", [
      "123".toString(),
      "484_11",
    ]);

    // const amount = ethers.utils.parseUnits('1', 'ether'); // Adjust amount as needed
    const transaction = {
      from: address,
      to: contractAddress,
      // value: "0x",
      data: data,
    };
    try {
      const result = await ethereum.request({
        method: "eth_sendTransaction",
        params: [transaction],
      });
      console.log("Contract signed in:", result);
      setState({
        ...state,
        ["transaction_contract"]: { ...transaction, hash: result },
      });
    } catch (error) {
      console.error(error.message || "error");
    }
  };

  const getAddressBalance = async () => {
    try {
      const balance = await ethereum.request({
        method: "eth_getBalance",
        params: [address, "latest"],
      });
      console.log("Address balance:", Number(balance).toString());
      return balance;
    } catch (error) {
      console.error("Address balance failed:", error);
    }
  };

  //   // Send Transaction Event
  //   const sendTransaction = async () => {
  //     try {
  //       const value = state.transaction.value.trim()

  //       if (value === '') {
  //         throw new Error('Please enter an amount.')
  //       }

  //       if (!/^\d*\.?\d+$/.test(value)) {
  //         throw new Error('Invalid amount. Please enter a valid number.')
  //       }

  //       const numValue = parseFloat(value)
  //       if (numValue <= 0) {
  //         throw new Error('Invalid amount. Please enter a positive number.')
  //       }

  //       if (!state.transaction.to || !ethers.isAddress(state.transaction.to)) {
  //         throw new Error('Invalid recipient address.')
  //       }

  //       const valueInWei = ethers.parseEther(value).toString()

  //       const result = await ethereum.request({
  //         method: 'eth_sendTransaction',
  //         params: [
  //           {
  //             from: address,
  //             to: state.transaction.to,
  //             value: valueInWei,
  //           },
  //         ],
  //       })
  //       setState({
  //         ...state,
  //         transaction: { ...state.transaction, hash: result },
  //       })
  //     } catch (error) {
  //       console.error('Transaction failed:', error)
  //     }
  //   }

  // Send Token Transaction Event
  //   const sendTokenTransaction = async () => {
  //     try {
  //       const { transaction_token } = state;
  //       const rpc = state.chainRPCs[0];
  //       const amount = transaction_token.amount;
  //       const to = transaction_token.toAddress;
  //       const contract = transaction_token.contractAddress;
  //       const provider = new ethers.JsonRpcProvider(rpc);
  //       const tokenContract = new ethers.Contract(contract, erc20Abi, provider);
  //       const decimals = await tokenContract.decimals();
  //       const data = tokenContract.interface.encodeFunctionData("transfer", [
  //         to,
  //         ethers.parseUnits(String(amount), decimals),
  //       ]);

  //       const transaction = {
  //         from: address,
  //         to: contract,
  //         data: data,
  //       };
  //       const result = await ethereum.request({
  //         method: "eth_sendTransaction",
  //         params: [transaction],
  //       });
  //       setState({
  //         ...state,
  //         ["transaction_token"]: { ...transaction_token, hash: result },
  //       });
  //     } catch (error) {
  //       console.error("Token Transaction failed:", error);
  //     }
  //   };

  //   // Approve Contract Event
  //   const approveEvent = async () => {
  //     const domSelect = document.querySelector("#approve_select");
  //     const domInput = document.querySelector("#approve_input_amount");

  //     const approveUnLimit = domSelect.value == 1;
  //     const amount = approveUnLimit
  //       ? ethers.MaxUint256
  //       : domInput.value == 0 || domInput.value == 2
  //       ? 0n
  //       : ethers.parseUnits(domInput.value, 0);

  //     const tokenAddress = state.transaction_contract.tokenAddress;
  //     const uniswapAddress = state.transaction_contract.uniswapAddress;

  //     // Create an instance of ethers.Contract
  //     const abiCoder = new ethers.AbiCoder();
  //     const iface = new ethers.Interface(approveABI);
  //     const data = iface.encodeFunctionData("approve", [uniswapAddress, amount]);

  //     // const amount = ethers.utils.parseUnits('1', 'ether'); // Adjust amount as needed
  //     const transaction = {
  //       from: address,
  //       to: tokenAddress,
  //       // value: "0x",
  //       data: data,
  //     };
  //     try {
  //       const result = await ethereum.request({
  //         method: "eth_sendTransaction",
  //         params: [transaction],
  //       });
  //       setState({
  //         ...state,
  //         ["transaction_token"]: { ...transaction, hash: result },
  //       });
  //     } catch (error) {
  //       console.error(error.message || "error");
  //     }
  //   };

  return (
    <div>
      <button onClick={connectWallet}>Connect to Wallet</button>
      <button onClick={disconnectWallet}>Disconnect Wallet</button>
      <button onClick={signMessage}>Sign Message</button>
      <button onClick={verifySignMessage}>Verify Signature</button>
      <button onClick={signInEvent}>Sign In Contract</button>
      <button onClick={getAddressBalance}>Get Address Balance</button>
    </div>
  );
};

export default UxuyComponent;
